.leaflet-control-attribution {
  display: none;
}

img {
  object-fit: contain;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edfeff 
}

.leaflet-container {
  width: 100%; 
  height: 100%;
}

a {
  text-decoration: none;
  // &:hover {
  //   text-decoration: underline #00000022;

  // }
}

.animate {
  transition: all 0.3s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex {
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
}
.rowcenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.f-c-sb {
  align-items: center;
  justify-content: space-between;
}

.f1 {
  flex: 1;
}

.fullscreen {
  height: 100vh;
  width: 100vw;
}

.p1 {
  padding: 1rem;
}

/* vertical children spacing */
.vcs {
  & > *:not(:first-child) {
    margin-top: 1rem;
  }
}
/* horizontal children spacing */
.hcs {
  & > *:not(:first-child) {
    margin-left: 1rem;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tacenter {
  text-align: center;
}

.darker {
  filter: brightness(90%);
  & * {
    @extend .darker;
  }
}

.hoverable {
  @extend .animate;
  &:hover {
    background-color: #00000009
  }
}

.clickable {
  @extend .animate;
  cursor: pointer;
  &:hover {
    @extend .darker;
  }
  & * {
    @extend .clickable;
  }
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}


.close {
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  background-color: black;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.img-fw {
  width: 100%;
  height: auto;
}

.p1 {
  padding: 1rem;
}

.br1 {
  border-radius: 1rem;
}

.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}

.MuiOutlinedInput-root {
  background-color: white;
  border-radius: 5rem;
}

.scroll-snap {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(300px);
  scroll-snap-type: y mandatory;
  &>* {
    scroll-snap-align: start;
  }
}

.card-bg {
  background-image:
    linear-gradient(
      #dcdcdc,
      #fafafa 150px
    );
}

.dimTxt {
  color: gray;
}

.page-container {
  padding-bottom: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}