.btnFacebook { 
  width: 100%;
  font-size: larger; 
  border-radius: 4px;
  background: #3b5998;
  color:white;
  border: 0px transparent;  
  text-align: center;
  padding: 1rem;
  cursor: pointer;
}
.btnGoogle { 
  padding: 1rem;
  width: 100%;
  font-size: larger; 
  border-radius: 4px;
  background: #db3236;
  color:white;
  border:0px transparent;
  text-align: center;
  cursor: pointer;
}
.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.8;
}
.btnGoogle:hover {
  background: #db3236;
  opacity: 0.8;
}